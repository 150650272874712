<template>
  <CModal
    ref="modal"
    :show="show"
    :submit-form="'create-update-merchant-wastes-form'"
    :submit-loading="submitLoading"
    @toggle="onToggle"
  >
    <template v-slot:header>
      <template v-if="mode === 'update'">
        {{ $t('shopCertificates.update', { name: shopCertificate.title }) }}
      </template>
      <template v-else>
        {{ $t('shopCertificates.add') }}
      </template>
    </template>
    <CForm 
      id="create-update-merchant-wastes-form" 
      @submit="onSubmit"
      :error-fields-response="errorFieldsResponse"
      custom-save-button
    >
      <CTextField
        v-model="shopCertificate.title"
        :label="$t('title')"
        required
      />
      <CDivider> {{ $t('certificate') }} </CDivider>
      <CUpload
        v-if="mode === 'create' || updateFile"
        v-model="shopCertificate.file"
        :label="$t('shopCertificates.uploadFile')"
        accept="application/pdf, image/png, image/jpeg"
        required
      />
      <CMessage v-if="fileToBig" variant="danger" class="mb-3">
        {{ $t('maxFileSize', { maxFileSize: '1 MB' }) }}
      </CMessage>

      <div v-if="mode === 'update'" class="mt-3 w-full">
        <img 
          v-if="!updateFile && oldShopCertificateFile" 
          :src="
            !oldShopCertificateFile.includes('.pdf') ? 
              oldShopCertificateFile : '/pdf-placeholder.png'
          "
          class="mb-3 h-48 mx-auto"
        >
        <CButton
          native-type="button"
          v-if="!updateFile"
          class="w-full"
          @click="updateFile = true"
        >
          {{ $t('shopCertificates.changeFile') }}
        </CButton>
        <CButton
          native-type="button"
          v-if="updateFile"
          class="w-full"
          @click="updateFile = false"
        >
          {{ $t('shopCertificates.useOldFile') }}
        </CButton>
      </div>
      
      <CDivider> {{ $t('badge') }} </CDivider>
      <CImageUpload
        class="mt-3"
        v-model="shopCertificate.badge"
        :label="$t('shopCertificates.uploadBadge')"
        no-cropper
      />
      <CMessage v-if="badgeToBig" variant="danger" class="mb-3">
        {{ $t('maxFileSize', { maxFileSize: '1 MB' }) }}
      </CMessage>
    </CForm>
    <template v-slot:submit>
      {{ $t('save') }}
    </template>
  </CModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CURRENT_SHOP_CERTIFICATE, THIS_SHOP } from '@/store/gettersTypes';
import { CREATE_SHOP_CERTIFICATE, UPDATE_SHOP_CERTIFICATE } from '@/store/actionTypes';

export default {
  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      shopCertificate: {
        title: '',
        file: null,
        badge: null,
      },
      updateFile: false,
      oldShopCertificateFile: null,
      errorFieldsResponse: null,
    };
  },

  computed: {
    ...mapGetters([CURRENT_SHOP_CERTIFICATE, THIS_SHOP]),
    submitLoading() {
      return this.$store.state.shopCertificates.createLoading;
    },
    fileToBig() {
      return !!this.updateFile && this.updateFile.size > 5000000;
    },
    badgeToBig() {
      return !!this.shopCertificate.badge && this.shopCertificate.badge.size > 1000000;
    },
  },

  watch: {
    show() {
      this.updateFile = false;
      this.oldShopCertificateFile = null;
      if (this.mode === 'update') {
        this.shopCertificate = {
          title: this.currentShopCertificate.title,
          file: null,
          badge: this.currentShopCertificate.badge,
        };
        this.oldShopCertificateFile = this.currentShopCertificate.file;
      } else {
        this.shopCertificate = {
          shopId: this.thisShop.id,
          title: '',
          file: null,
          badge: null,
        };
      }
    },
    shopCertificate: {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([CREATE_SHOP_CERTIFICATE, UPDATE_SHOP_CERTIFICATE]),
    onToggle(show) {
      this.$emit('toggle', show);
      if (!show) {
        this.$emit('close');
      }
    },
    async onSubmit() {
      if (this.mode === 'create') {
        try {
          await this.createShopCertificate(this.shopCertificate);
        } catch (e) {
          if (e.response.data.code === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
            return;
          }
          throw e;
        }
      } else {
        const body = {
          title: this.shopCertificate.title,
        };

        if (this.updateFile) {
          body.file = this.shopCertificate.file;
        }

        if (typeof this.shopCertificate.badge !== 'string') {
          body.badge = this.shopCertificate.badge;
        }

        try {
          await this.updateShopCertificate({ id: this.currentShopCertificate.id, body });
        } catch (e) {
          if (e.response.data.code === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
            return;
          }
          throw e;
        }
      }

      this.onToggle(false);
    },
  },
};
</script>
