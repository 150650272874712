<template>
  <div>
    <GridSelector
      v-if="!loading"
      show-add-button
      @open-create="openModal"
      @open-update="openModal"
    />
    <div v-else class="h-[300px] flex items-center justify-center">
      <CLoadingSpinner class="w-6" />
    </div>
    <Portal to="overlays">
      <CreateUpdateModal v-model="showCreateUpdateModal" :mode="mode" />
    </Portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GridSelector from '@/ui/components/Merchant/ShopCertificates/GridSelector.vue';
import CreateUpdateModal from '@/ui/components/Merchant/ShopCertificates/CreateUpdateModal.vue';
import { GET_SHOP_CERTIFICATES } from '@/store/actionTypes';

export default {
  components: {
    GridSelector,
    CreateUpdateModal,
  },

  data: () => ({
    showCreateUpdateModal: false,
    mode: 'create',
  }),

  computed: {
    loading() {
      return this.$store.state.shopCertificates.loading;
    },
  },

  created() {
    this.getShopCertificates();
  },

  methods: {
    ...mapActions([GET_SHOP_CERTIFICATES]),
    openModal(data) {
      if (data) {
        this.mode = 'update';
      } else {
        this.mode = 'create';
      }
      this.showCreateUpdateModal = true;
    },
  },
};
</script>