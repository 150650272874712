<template>
  <div
    class="border bg-white flex flex-col items-center justify-center
      rounded-lg shadow-sm p-3 md:p-4 text-gray-700 transition duration-150 select-none"
  >
    <div class="flex justify-center mb-2">
      <img :src="image" class="rounded select-none h-28">
    </div>
    <CTypo tstyle="title3" class="text-center">{{ data.title }}</CTypo>
    <div class="inline-flex mt-2">
      <CButton size="small" rounded @click="emitUpdate">{{ $t('update') }}</CButton>
      <CButton
        icon="trash-outline"
        size="small"
        variant="danger"
        inverted
        rounded
        class="ml-1"
        @click="emitDelete"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    createItem: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    image() {
      if (this.data.badge) return this.data.badge;
      if (!this.data.file.includes('.pdf')) return this.data.file;
      return '/pdf-placeholder.png';
    },
  },

  methods: {
    emitDelete() {
      this.$emit('delete', this.data.id);
    },
    emitUpdate() {
      this.$emit('update', this.data);
    },
  },
};
</script>
