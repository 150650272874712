<template>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <GridSelectorItem
      v-for="shopCertificate in allShopCertificates"
      :key="shopCertificate.id"
      :data="shopCertificate"
      @update="openCreateUpdateModal(shopCertificate)"
      @delete="openDeleteDialog(shopCertificate)"
    />
    <button
      v-if="showAddButton"
      class="border bg-white flex flex-col items-center justify-center
      rounded-lg shadow-sm p-4 text-gray-700 focus:outline-none focus:ring-primary-3
      hover:shadow-lg transition duration-150"
      @click="openCreateUpdateModal(null)"
    >
      <CIcon name="plus-circle" size="24" />
      <CTypo el="div" tstyle="subheadline" class="mt-4">
        {{ $t('shopCertificates.add') }}
      </CTypo>
    </button>
    <Portal to="overlays">
      <CModal
        v-model="deleteDialog"
        variant="dialog"
        submit-variant="danger"
        header-icon="exclamation-circle"
        :submit-countdown="1"
        :submit-loading="deleteLoading"
        @submit="onDelete"
      >
        <template v-slot:header>
          {{ $t('actionQuestion.confirmDelete') }}
        </template>
        {{ $t('actionQuestions.shopCertificate.reallyWantToDelete', { name: deleteObjName }) }}
        <template v-slot:submit>{{ $t('delete') }}</template>
      </CModal>
    </Portal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { DELETE_SHOP_CERTIFICATE } from '@/store/actionTypes';
import { ALL_SHOP_CERTIFICATES } from '@/store/gettersTypes';
import { OPEN_SHOP_CERTIFICATE } from '@/store/mutationsTypes';
import GridSelectorItem from './GridSelectorItem.vue';

export default {
  components: {
    GridSelectorItem,
  },

  props: {
    showAddButton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    deleteDialog: false,
    deleteObj: null,
  }),

  computed: {
    ...mapGetters([ALL_SHOP_CERTIFICATES]),
    deleteLoading() {
      return this.$store.state.shopCertificates.updateLoading;
    },
    deleteObjName() {
      if (this.deleteObj) {
        return this.deleteObj.title;
      }
      return null;
    },
  },

  methods: {
    ...mapActions([DELETE_SHOP_CERTIFICATE]),
    ...mapMutations([OPEN_SHOP_CERTIFICATE]),
    openCreateUpdateModal(data = null) {
      if (data === null) {
        this.$emit('open-create', null);
      } else {
        this.openShopCertificate(data.id);
        this.$emit('open-update', data);
      }
    },
    openDeleteDialog(shopCertificate) {
      this.deleteObj = shopCertificate;
      this.deleteDialog = true;
    },
    onDelete() {
      this.deleteShopCertificate(this.deleteObj.id).then(() => {
        this.deleteDialog = false;
      });
    },
  },
};
</script>
